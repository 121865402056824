
.nav-wrap {
  width: 100%;
  overflow: hidden;
}

.navbars {
  margin: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  a {
    text-decoration: none;
    color: #333;

    &:hover {
      text-decoration: none;
      color: #333;
    }
  }

  .col {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .col.brand {
    flex: 0 0 auto;
    width: 150px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media (max-width: 1024px) {
    .brand {
      display: none;
    }
  }

  .col.nav {
    display: flex;
    flex: 1;
    justify-content: center;

    .nav-link {
      width: 100px;
      line-height: 48px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      color: #0F0E4C;
      font-weight: 600;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  .col.account {
    flex: 0 0 auto;
    justify-content: flex-end;
    width: 300px;
    margin-left: auto;
  }
}

.btn {
  font-weight: bold;
  height: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  * {
    vertical-align: middle;
  }
}

.nav-btn {
  border-width: 2px;
  border-color: #343a40;
  border-radius: 20px;
  padding: 0.5rem 20px;
  margin: 0 5px;
  transition: all 0.2s;
}

.btn-signup {
  color: #fff;
  background-color: #0F0E4C;

  &:hover {
    color: #0F0E4C;
    background-color: #fff;
  }
}

.btn-signin,
.btn-signout {
  color: #0F0E4C;
  background-color: #fff;

  &:hover {
    color: #fff;
    background-color: #0F0E4C;
  }
}

.btn-user {
  border: none;
  color: #0F0E4C;
  background-color: #fff;
  margin: 0;
  white-space: nowrap;
  border-radius: 0;
  // border-bottom: 2px double black;
}
