
.annotation-bullet-bar {
  pointer-events: none;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  .clickable {
    pointer-events: auto;
  }

  .time-range {
    font-size: 0.7rem;
    font-weight: 700;
  }

  .left-pane {
    flex: 1 0;
  }

  .right-pane {
    flex: 0 0 150px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: auto;
    margin-left: auto;

    .volume-slider {
      width: 100px;
      height: 30px;
      transform: rotate(-90deg) translateX(30px) translateY(35px);
    }

    .auto-continue {
      margin-right: 30px;
      margin-bottom: 12px;

    }
  }
}
