<template>
  <div class="shadow-mask" v-if="isVisible">
    <dialog :open="isVisible" @close="close">
      <header>
        <slot name="title" />
        <button class="btn close-x" @click="close">X</button>
      </header>
      <section class="main">
        <slot />
      </section>
      <footer class="btn group">
        <button v-if="!hideCloseButton" class="btn close" @click="close">Close</button>
      </footer>
    </dialog>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

defineProps({
  hideCloseButton: {
    type: Boolean,
    default: false,
  },
});


const emit = defineEmits(['close']);
const isVisible = ref(false);

const open = () => {
  isVisible.value = true;
};

const close = () => {
  isVisible.value = false;
  emit('close');
};

defineExpose({ open, close });
</script>

<style scoped>
.shadow-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

}

dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 200px;
  max-width: 30%;
  height: auto;
  min-height: 200px;
  border: solid 1px #000;
  padding: 10px;
  margin: 0;
  transform: translate(-50%, -50%);
}

.main {
  margin: 0;
  padding: 0;
}

.btn.group {
  bottom: 0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn.close {
  margin-top: 10px;
  border: solid 1px #000;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
  font-weight: bold;
}

.btn.close-x {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-weight: bold;
  font-size: 1em;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
}
</style>
