
.main-sec {
  background-image: url("@/assets/signup.jpg");
  background-size: cover;

  .inner-sec {
    max-width: 1600px;
    margin: auto;
    padding: 80px 20px;

    h3 {
      margin: 0;
      color: #FFF;
      font-size: 40px;
      font-weight: 700;
    }

    span {
      display: block;
      color: #FFF;
      font-size: 20px;
      font-weight: 400;
      margin-top: 15px;
    }

    .components {
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 30px;

      .gird-sec {

        .grid-title {
          border-radius: 10px;
          background: #071A3C;
          color: white;
          padding: 12px 20px;
          font-size: 18px;
        }

        .grid-content {

          .Q-section {
            position: relative;
            width: 100%;

            .inner-Q-section {
              max-width: 800px;
              margin: 0 auto;
              border-radius: 10px;

              .q-section-wrap {
                margin: 20px 0;
                border-radius: 10px;

                dl {
                  overflow: hidden;
                  margin: 0;
                  cursor: pointer;

                  dt {
                    color: #FFF;
                    background: rgba(7, 26, 60, 0.5);
                    padding: 20px;


                    p {
                      color: #FFF;
                      margin: 0;
                    }
                  }

                  .dd-none {
                    display: block;
                    margin: 0;
                  }

                  .dd-block {
                    display: none;
                  }

                  .demo-available {
                    color: gold;
                    font-size: 10px;
                    padding-left: 1em;
                  }

                  .link-disabled {
                    color: #CCC;
                  }

                  a.link-disabled {
                    cursor: not-allowed;
                  }

                  dd {
                    display: none;
                    box-sizing: border-box;
                    background: #4E4E4E;

                    div {
                      padding: 15px 20px;
                      cursor: pointer;

                      a {
                        color: #FFF;
                        font-size: 14px;
                        margin: 0;
                        text-decoration: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media(max-width: 1020px) {

  .components {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media(max-width: 700px) {

  .components {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
