
.video-wrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #999;
  margin: 0 auto;

  .video-window {
    position: relative;
    width: 100%;
  }

  .next-video {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 1px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-size: cover;
    overflow: hidden;
    object-fit: fill;

    img {
      object-fit: cover;
    }
  }

  .model-name {
    pointer-events: none;

    h3 {
      font-family: "Roboto", sans-serif;
      font-size: 35px;
      color: white;
      margin: 0;
      padding: 10px 10px;
    }
  }

  .annotation {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;

    .overlay-tag {
      /* border: 3px solid blue; */
      position: absolute;
      pointer-events: all;
      cursor: pointer;
      object-fit: contain;

      &:hover {
        border: 3px solid rgba(0, 0, 255, 0.3);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    h5 {
      visibility: hidden;
      color: white;
      line-height: 1.5em;
      background-color: blue;
      margin: 0;
      padding: 0;
    }
  }

  .seeker-bar {
    position: absolute;
    width: 100%;
    height: 150px;
    left: 0;
    bottom: 0px;
    z-index: 10;
  }
}

.overlay-popup-window {
  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.inline {
  display: flex;
  justify-content: flex-start;
}

.edit-button {
  margin-left: 10px;
}

.subtask-list-button {
  position: absolute;
  box-sizing: border-box;
  pointer-events: all;
  cursor: pointer;
}

.subtask-list {
  position: absolute;
  pointer-events: all;
  box-sizing: border-box;
  bottom: 0;
  right: 0;
  width: 40vw;
  height: 30vh;
  border: 3px solid black;
  background: white;
  opacity: 0.8;
  z-index: 32;

  @media (max-width: 800px) {
    width: 80vw;
  }

  a {
    font-size: 1em;
    text-decoration: none;

    &:hover {
      font-weight: bold;
    }
  }
}

.feedback-button {
  position: absolute;
  top: 100px;
  right: 60px;
  width: 55px;
  height: 49px;
  z-index: 30;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
