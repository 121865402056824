
#app-main {


  header {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 100;
    border-bottom: 1px solid #e0e0e0;

    /* mobile */
    @media (max-width: 1024px) {}

    @media (max-width: 500px) {}
  }

  .footer {
    position: sticky;
    z-index: 30;
    border-top: 1px solid rgb(221, 221, 221);
    width: 100%;
    background-color: #fff;
    bottom: 30px;
    //padding-bottom: 30px;
    // ref: https://bootcamp.uxdesign.cc/you-have-to-start-using-this-css-property-in-your-websites-7353f46def79
    padding-bottom: calc(env(safe-area-inset-bottom) + 30px);

    * {
      font: normal normal 600 16px/22px Open Sans;
      font-size: 18px;
      color: #333;
    }
  }
}
