
.wrapper {
  width: 100%;
  height: auto;
  overflow-y: visible;
  margin: 0;
  padding: 0;

  table {
    width: 100%;
    color: #fff;

    thead > tr:first-child > th:first-child {
      border-radius: 15px 0 0 0;
    }

    thead > tr:first-child > th:last-child {
      border-radius: 0 15px 0 0;
    }

    tbody > tr:last-child > td:first-child {
      border-radius: 0 0 0 15px;
    }

    tbody > tr:last-child > td:last-child {
      border-radius: 0 0 15px 0;
    }

    th {
      background: #393939;
      border-bottom: 1px solid #666;
      padding: 7px;
    }

    td {
      border-right: 1px solid #666;
      background: #494949;
      border-bottom: 1px solid #666;
      padding: 7px;

      &.line-none {
        border-right: 0;
      }
    }
  }

  textarea.bar-style {
    width: 100%;
    min-height: 10rem;
    max-height: 20rem;
    overflow-y: scroll;
  }
}
