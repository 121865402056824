
.body {
  height: 100vh;

  background-image: url("~@/assets/SignInUpPage/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  font-family: sans-serif;
}

.main-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  height: 95%;
  min-height: 800px;
  overflow-y: auto;
  padding: 0 5em;
  gap: 50px;
}

button {
  border-radius: 4px;
  background-color: navy;
  border: none;
  color: white;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;

  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.col {
  width: 100%;
  border: 0;
  border-radius: 3px;
  box-shadow: 5px 5px 5px #666;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 30px 0;
  min-width: 500px;
  max-width: 800px;

  h2 {
    width: 100%;
    text-align: center;
    font-family: sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: navy;
  }

  p {
    width: 100%;
    height: 2em;
  }

  label {
    display: inline-block;
    width: 30%;
    min-width: 2em;
    font-size: 1.1em;
    font-weight: 600;
    color: navy;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;

    @media (min-width: 1024px) {
      font-size: 1.5em;
    }

    &.privacy-policy-agreement {
      padding: 0 5px;
      text-overflow: ellipsis;
      font-size: 1em !important;
    }
  }

  input,
  select {
    width: 50%;
    margin: auto 5%;
    font-size: 16px;
  }
}

.edit-profile-area {
  .edit-profile-button,.delete-account-button {
    position: relative;
    display: flex;
    gap: 10%;
    justify-content: space-around;
    align-items: bottom;
    padding: 0 10%;
    height: auto;

    button {
      width: 300px;
    }

    .confirm.disabled {
      opacity: 1;
    }

    .cancel:hover {
      background-color: rgb(4, 4, 47);
    }
  }

  .delete-account-button {
    margin-top: 20px;
    margin-bottom: 20px;

    button {
      background-color: red;
    }
  }
}

footer {
  width: 100%;
  height: 5%;
  text-align: center;
  font-family: sans-serif;
  font-size: 16px;
  color: #333;
}
