
.subtasklist-wrapper {
  overflow-y: scroll;
  padding: 5px;
}

h2 {
  font-size: 14px;
}

.subtasklist {
  list-style-type: disc;
  margin: 0;
  padding: 0 15px;

  a {
    line-height: 1.2em;
    font-size: 1.6em;
    text-decoration: none;

    &.bold {
      font-weight: bold;
    }

    &:hover {
      color: purple;
      font-weight: bold;
    }
  }
}
