
.main-container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.main-container.submitting {
  pointer-events: none;
  opacity: 0.5;
}

button {
  border-radius: 50px;
  background-color: white;
  border: 1px solid #0F0E4C;
  color: #0F0E4C;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  width: 150px;
  cursor: pointer;
  margin: 30px 0;
  font-weight: 700;
  cursor: pointer;
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 900;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
  }
}

.button-sign {
  border-radius: 50px;
  background-color: #0F0E4C;
  border: 1px solid #0F0E4C;
  color: white;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  width: 150px;
  cursor: pointer;
  margin: 30px 0;
  font-weight: 700;
  cursor: pointer;
}

.col {
  width: 50%;
  padding: 0 100px;
  margin: 120px 0;

  .signup-go {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    padding: 0 20px;
    margin-top: 20px;
    text-align: end;
    color: #bbb;

    a {
      color: #4d4d4d;
    }
  }

  .signup-form {
    background: #FFF;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.10);
    padding: 40px;
  }

  p {
    width: 100%;
    padding-top: 10px;
  }

  label {
    display: block;
    color: #222;
    font-size: 18px;
    font-weight: 500;

    b {
      color: #F00;
      font-size: 15px;
      font-weight: 500;
    }

    @media (min-width: 1024px) {}

    &.privacy-policy-agreement {
      padding: 0 5px;
      text-overflow: ellipsis;
      font-size: 1em !important;
    }
  }

  input,
  select {
    margin-top: 5px;
    padding: 10px 5px;
    display: block;
    width: 100%;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #bbb;

    &::placeholder {
      @extend %my-placeholder;
    }
  }
}

%my-placeholder {
  color: #9E9E9E;
  font-size: 14px;
  font-weight: 400;
}

.singin-img {
  width: 50%;
  height: calc(100vh - 87px);
  background-image: url("@/assets/signup.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: sticky;
  top: 87px;

  img {
    width: 100%;
    height: 100%;
  }
}

.signup-area {
  .privacy-policy {
    padding-top: 20px;
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-start;

    label {
      margin: 0 8px;
      font-size: 16px;
      color: #666;
      font-weight: 400;
    }

    input {
      width: 24px;
      margin: 0;
      padding: 0;
    }
  }

  .sign-up-button {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: bottom;
    gap: 20px;
    height: auto;

    button {
      width: 100%;
    }

    .confirm.disabled {
      opacity: 1;
    }
  }
}

@media(max-width: 1024px) {
  .singin-img {
    display: none;
  }

  .col {
    width: 100%;
    padding: 0 20px;
    margin: 60px 0;
  }
}
