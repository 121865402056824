
.main {
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;

  .description {
    flex: 1;
    font-size: 1.5rem;
    max-width: 1280px;
    color: inherit;
    background-color: transparent;
  }

  .videos {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 50px;

    .card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      max-width: 1280px;

      figure {
        width: 560px;
      }

      figcaption {
        width: 600px;
        padding: 30px;

        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}
