
.popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  top: 10px;
  min-width: 100px;
  max-width: 45%;
  min-height: 30%;
  max-height: 90%;
  overflow: scroll;
  border: 3px solid #333;
  border-radius: 10px;
  background-color: #fff;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &.right {
    right: 10px;
  }

  &.left {
    left: 10px;
  }
}

header {
  display: flex;
  flex-basis: 32px;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 32px;
}

.close-button {
  top: 2px;
  right: 12px;
  width: 32px;
  height: 30px;
  border: none;
  border-radius: 3px;
  color: #222;
  background-color: transparent;
  z-index: 30;
  font-family: monospace;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 2px;
  text-align: center;
  margin: 0;
  margin-left: auto;
  padding: 1px;

  &:hover {
    background-color: #e33;
  }
}

.popup-content {
  display: flex;
  flex: 1 1 0%;
  overflow: auto;
}

.popup-content :deep() > * {
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.popup-content :deep(.link-url) {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: black;
  background-color: #eee;
}

.popup-content :deep(.fit-container) {
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.popup-content :deep(.note) {
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.popup-content :deep(iframe) {
  width: 100%;
  height: 100%;
  overflow: visible;
}
