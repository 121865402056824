
.main {
  margin: 0 auto;
  padding: 80px 20px;

  .info-sec {
    display: flex;
    gap: 100px;

    .user-sec {
      width: 45%;

      form {

        h4 {
          color: #0F0E4C;
          font-size: 30px;
          font-weight: 700;
          line-height: 40px;
          letter-spacing: -1px;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0;
          margin-inline-end: 0;
          margin-bottom: 40px;
        }
      }
    }

    .aside-right {
      width: 55%;
    }
  }
}

.contact-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 80px;

  .box-wrap {
    border: 1px solid #DDD;
    background: #FFF;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.10);
    padding: 60px;
    text-align: center;

    div {
      margin-top: 20px;

      span {
        font-weight: 700;
        font-size: 18px;
      }

      p {
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }
}

.flex-row {
  width: 100%;
  gap: 10%;

  div {
    width: 45%;
    margin-top: 20px;
  }

  h2 {
    color: #0F0E4C;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 60px;
  }

  h4 {
    color: #0F0E4C;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .left {
    float: left;
  }

  .right {
    float: right;
    text-align: right;
  }

  label {
    display: block;
    color: #222;
    font-size: 18px;
    font-weight: 500;
  }

  input,
  textarea {
    display: block;
    margin-top: 15px;
    padding: 10px 5px;
    display: block;
    width: 100%;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #DDD;
  }

  .red {
    color: red;
  }

  .width-100p {
    width: 100%;
  }
}

.submit {
  width: 100%;
  height: 50px;
  padding: 15px 0;
  margin-bottom: 30px;

  button {
    border-radius: 50px;
    border: 1px solid #0F0E4C;
    background: #FFF;
    cursor: pointer;
    width: 100%;
    padding: 15px 0;
    color: #0F0E4C;
    font-weight: bold;
    margin-top: 10px;
  }
}

footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  font-family: sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 0.3rem;
  height: 50px;
}


@media (max-width: 1020px) {
  .flex-row {
    line-height: normal;
  }

  .main {
    padding: 80px 20px;

    div {
      width: 100%;
    }

    .contact-box {
      grid-template-columns: repeat(2, 1fr);

      .main-btn {
        margin-top: 60px;
        padding: 30px 0;
      }
    }
  }

  .info-sec {
    display: block;

    .aside-right {
      visibility: hidden;
    }
  }

}


@media (max-width: 700px) {

  .main {
    padding: 80px 20px;

    div {
      width: 100% !important;
    }

    .contact-box {
      grid-template-columns: repeat(1, 1fr);

      .box-wrap {

        div {

          p {
            position: relative !important;
          }
        }
      }
    }

    .info-sec {
      display: block;

      .aside-right {
        visibility: hidden;
      }
    }
  }
}
