
.seeker-bar {
  pointer-events: none;

  .clickable {
    pointer-events: auto;
  }

  .non-clickable {
    pointer-events: none;
  }

  .hover-preview {
    position: absolute;
    width: 180px;
    height: 100px;
    border: 1px solid #000;
  }
}
