
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  /* pointer-events: none; */
  transition: opacity 0.5s;
}

.content-window {
  pointer-events: all;
  position: absolute;
  z-index: 110;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 480px;
  height: auto;
  border: 1px solid #000;
  border-radius: 5px;
  background: white;
  padding: 20px;
  overflow: hidden;


  h2 {
    display: inline-block;
    font-family: Noto Sans, Avenir, Helvetica, Arial, sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: #0F0E4C;
    padding: 0;
    margin: 0;
  }

  .footer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }

  .close {
    position: absolute;
    right: 20px;
    top: 10px;
    border: none;
    background: white;
    font-size: 30px;
    margin: 0;
    padding: 0;
  }

  .close2 {
    border-radius: 50px;
    border: 1px solid #0F0E4C;
    background: #FFF;
    display: flex;
    width: 100%;
    padding: 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 11.944px;
    font-size: 14px;
    font-weight: bold;
    color: #0F0E4C;
    text-decoration: none;
  }

  .modal-row {
    width: 100%;
    text-align: left;
    padding: 0;

    p {
      font-weight: 500;
    }
  }

  .text-main {
    font-size: 16px;
    line-height: 18px;
    font-weight: 800;
    text-align: center;
  }
}

@media(max-width: 700px) {
  .content-window {
    width: 348px;
  }
}
