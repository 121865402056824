
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: #000;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 100;
  pointer-events: none;
}

.show-modal {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: all;
}

.modal-frame {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  top: 50%;
  left: 50%;
  border-radius: 0px;
  width: 800px;
  height: auto;
  padding: 40px 50px;
  background-color: white;
  transform: translateX(-50%) translateY(-50%);
}

.title {
  font-size: 1.1rem;
  line-height: 2rem;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 50px 0 10px 0;

  button {
    width: 150px;
    height: 30px;
    font-size: 14px;
    cursor: pointer;
  }

  button.cancel {
    display: flex;
    padding: 20px 120px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid #0F0E4C;
    background-color: #fff;
    color: #071A3C;
  }

  button.submit {
    display: flex;
    padding: 20px 120px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #0F0E4C;
    color: #fff;
  }
}

.form {
  width: 100%;

  .form-wrap{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
  }

  div{
    display: block;

    label {
      display: block;
      color: #222;
      font-size: 18px;
      font-weight: 500;
    }

    select{
      margin-top: 7px;
      padding: 10px 5px;
      display: block;
      width: 100%;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid #DDD;
    }

    input,
    textarea {
      display: block;
      margin-top: 5px;
      padding: 10px 5px;
      display: block;
      width: 100%;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid #DDD;
      }
  }
}
