
.wrapper {
  display: grid;
  grid-template-columns: 500px auto;
  grid-template-rows: 1fr;
  height: calc(100vh - 200px);
  background: #eee;
}

.header {
  grid-column: 1 / 3;
  grid-row: 1;
  height: 50px;
}

.left-pane {
  grid-column: 1;
  grid-row: 1;

  label {
    display: inline-block;
    padding: 0.5em 5px;
    color: #333;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    vertical-align: middle;
    background-color: #aaf;
    cursor: pointer;
    border: 1px solid #aaa;
    border-radius: 25px;
    width: 95%;
  }

  input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}

.right-pane {
  grid-column: 2;
  grid-row: 1;

  .video-container {
    display: flex;

    video,
    canvas {
      border: 1px solid #ccc;
    }
  }
}

.props {
  width: 100%;

  th,
  td {
    padding: 3px 10px;
  }

  th {
    background-color: #aaa;
  }

  td {
    background-color: #ccc;
  }
}

.submit {
  position: relative;
  float: left;
  bottom: 0;
  width: 100%;
  height: 50px;
  font-size: 24px;
  background-color: lightgreen;
}
