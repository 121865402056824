
.wrapper {
  width: 100%;
  height: 100%;
  overflow-y: visible;
  margin: 0;
  padding: 0;

  .label-input {
    width: 100%;
    background-color: inherit;
    border: none;
    font-size: 1.2em;
  }

  table {
    width: 100%;
    color: #fff;

    thead>tr:first-child>th:first-child {
      border-radius: 15px 0 0 0;
    }

    thead>tr:first-child>th:last-child {
      border-radius: 0 15px 0 0;
    }

    tbody>tr:last-child>td:first-child {
      border-radius: 0 0 0 15px;
    }

    tbody>tr:last-child>td:last-child {
      border-radius: 0 0 15px 0;
    }

    th {
      background: #393939;
      border-bottom: 1px solid #666;
      padding: 7px;
    }

    td {
      border-right: 1px solid #666;
      background: #494949;
      border-bottom: 1px solid #666;
      padding: 7px;

      &.line-none {
        border-right: 0;
      }
    }
  }

  textarea.contents-html {
    width: 100%;
    min-height: 10rem;
    max-height: 20rem;
    overflow-y: scroll;
  }

  .tabnav {
    button {
      border: 1px solid pink;
      background: #eee;
      padding: 10px;
    }
  }
}
