
.hero.container {
  position: relative;
  height: 85vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;

    @media (max-width: 700px) {
      object-fit: contain;
    }
  }

  .title {
    position: relative;
    z-index: 1;
    color: #FFF;
    font-size: 70px;
    font-weight: 700;
    text-align: center;
    text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
    margin-bottom: 30px;

    @media (max-width: 700px) {
      font-size: 40px;
    }

    @keyframes fadeout {
      0% {
        opacity: 1;
      }

      80% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    &.fadeout {
      animation: fadeout 5s linear;
      animation-fill-mode: forwards;
    }
  }

  .scroll-down {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
}

.airplane {
  width: 100%;
  overflow-x: hidden;

  .slides-group {
    .slide-contents {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      .main-img-text {

        h2 {
          color: #FFF;
          text-align: center;
          text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
          font-size: 70px;
          font-weight: 700;
          margin-bottom: 30px;
        }

        p {
          color: #FFF;
          text-align: center;
          text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
          font-size: 30px;
          font-weight: 500;
          text-decoration-line: underline;
        }

        div {
          display: flex;
          justify-content: center;
          margin-top: 30px;
          cursor: pointer;


          span {
            color: #FFF;
            background: #0F0E4D;
            padding: 10px 40px;
            border-radius: 50px;
          }
        }
      }
    }

    img {
      z-index: 1;
      height: calc(100vh - 87px);
      width: 100%;
    }
  }
}

.overlay {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  cursor: pointer;
  border: 2px solid #2f528f;
  border-radius: 15px;
  background: #7486a085;
  color: white;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  text-decoration: none;
}

.overlay:hover {
  border: 2px solid purple;
}

.landing-gear {
  top: 72%;
  left: 30%;
  width: 10%;
  height: 11%;
}

.powerplant {
  top: 55%;
  left: 35%;
  width: 10%;
  height: 11%;
}

.description {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 1600px;
  margin-top: 80px;

  h3 {
    width: 100%;
    color: #0F0E4C;
    font-size: 40px;
    font-weight: 800;
  }

  p {
    width: 100%;
    color: #333;
    font-size: 18px;
    font-weight: 400;
  }

  .banner-logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .return-to-top-row {
    display: flex;

    .return-to-top-button {
      width: 350px;
      height: 48px;
      border: 1px solid #333;
      border-radius: 5px;
      margin-bottom: 20px;
    }
  }

  .main-adress {
    margin: 80px 0 120px 0;
    display: flex;
    width: 100%;
    gap: 40px;

    .main-adress-btn {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 2px solid #0F0E4C;
      border-bottom: 1px solid #0F0E4C;
      padding: 25px 20px;
      text-decoration: none;

      span {
        color: #222;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

.video-cards {
  margin: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  figure {
    border: none;
    display: block;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
  }

  .card {
    flex: 1;

    .video-wrap {
      width: 100%;
      position: relative;

      .card-hover {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        text-align: center;
        width: 100%;
        height: 98%;
        z-index: 10;
        opacity: 0;
        color: #FFF;
        font-size: 16px;

        &:hover {
          background-color: rgba(15, 14, 76, 0.7);
          opacity: 1;
        }
      }
    }
  }

  video {
    width: 100%;
    height: 100%;

    &:hover {
      background: #0F0E4C;
      z-index: 10;
    }
  }

  figcaption {
    text-align: center;
  }
}

.brochure-row {
  color: rgb(38, 189, 189);
}

.brochure-link {
  color: inherit;
  text-decoration: underline;

  &:hover {
    font-weight: bold;
  }
}

#slider {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.slide {
  width: 100%;
  height: calc(100vh - 87px);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    position: absolute;
    color: white;
    border-radius: 50px;
    background: #0F0E4D;
    padding: 16px 50px;
    top: 80px;
    left: 100px;
    white-space: nowrap;
    font-size: 28px;
  }

  span {
    border-bottom: 1px solid #fff;
    position: absolute;
    padding-bottom: 10px;
    bottom: 100px;
    right: 100px;
    color: #FFF;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
  }
}

.btn {
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  position: absolute;
  top: calc(50% - 35px);
  left: 1%;
  transition: transform 0.3s ease-in-out;
  user-select: none;
  color: #FFF;
  font-size: 30px;
}

.btn-next {
  left: auto;
  right: 1%;
}

.btn:hover {
  transform: scale(1.1);
}

.features {
  margin: 30px 0;
  padding: 0 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  li {
    width: 100%;
    color: #333;
    font-size: 18px;
    font-weight: 400;
    list-style-type: none;
  }

  li::before {
    content: "✈";
    margin-right: 0.5em;
  }
}

@media (max-width: 1024px) {

  .description {
    padding: 0px 20px;
  }
}

@media (max-width: 700px) {

  .description {
    padding: 0px 20px;

    .video-cards {
      display: block;

      .video-wrap {
        margin-bottom: 20px;
      }
    }

    .main-adress {
      display: block;
      margin: 80px 0 60px 0;

      .main-adress-btn {
        margin-bottom: 30px;
      }
    }
  }

  .slide {
    width: 100%;
    height: calc(100vh - 87px);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      position: absolute;
      color: white;
      border-radius: 50px;
      background: #0F0E4D;
      padding: 10px;
      top: 60px;
      left: 20px;
    }

    span {
      border-bottom: 1px solid #fff;
      position: absolute;
      padding-bottom: 10px;
      bottom: 100px;
      right: 10px;
      color: #FFF;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
