
.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px;

  overflow: hidden;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    object-fit: cover;
    opacity: 0.33;
    overflow: hidden;
    pointer-events: none;
  }
}

h1 {
  font-size: 3rem;
}

.notification {
  border: 3px double #333;
  border-radius: 15px;
  width: 500px;
  padding: 0 15px;
  margin: 30px;
  vertical-align: top;

  h3 {
    font-size: 2rem;
    line-height: 3rem;
    text-align: center;
    padding: 0;
    margin: 10px 0;
    background: #aaa;
    border-radius: 15px;
  }
}

.buttons {
  display: flex;
  gap: 5rem;
}

.btn {
  cursor: pointer;
  border-radius: 10px;
  width: 350px;
  height: 50px;
  font-weight: 700;
  transition: all .3s ease-in-out;
}

.btn-green {
  border: 3px solid #363;
  background: #6a3;
}

.btn-green:hover {
  background: #6a3;
  color: #fff;
}
