
.main {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  scroll-behavior: smooth;
}

h2 {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
}

.close-button {
  cursor: pointer;
  margin: 0 calc(50% - 50px);
  width: 100px;
  height: 30px;
  border-radius: 5px;
  background-color: #666;
  color: white;
  font-family: sans-serif;
  font-weight: bold;
  box-shadow: none;
  border: none;
}
