
.container {
  width: 100vw;
  max-width: 100%;
  min-height: 100%;
  overflow: auto;
  padding: 0;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  align-content: center;
  justify-content: space-between;

  background-color: #eee;

  section {
    display: block;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .video-view {
    min-height: 100vh;
    overflow: hidden;
  }
}
