
* {
  box-sizing: border-box;
}

.modal-wrapper {
  width: 100vw;
  height: calc(100vh - 200px);
  position: relative;
  z-index: 50;
}

.modal-body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  /* display: hidden; */
  z-index: 500;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s;

  .progress-window {
    position: fixed;
    top: 50%;
    left: calc(50% - 150px);
    width: 300px;
    height: 4rem;
    border-radius: 10px;
    border: 3px double pink;
    background: white;
    text-align: center;
  }
}

.component-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  width: 100%;
  height: 100%;
  grid-gap: 0;
  grid-template-columns: 1fr 500px;
  grid-template-rows: 44px 1fr;
  grid-template-areas:
    "header header"
    "video-area right-pane";
  background: #5c5c5c;
  margin: 0;
  padding: 0;
  z-index: 50;
}

.toolbar {
  grid-area: header;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 40px;

  width: 100%;
  height: 44px;
  background-color: #2f2f2f;

  .btn {
    font-size: 32px;
    background-color: inherit;
    color: #fff;
    border: none;
    padding: 0 15px;

    &:nth-child(1) {
      padding: 0 50px 0 0;
    }
  }
}

.loading-box {
  display: flex;
  text-align: center;
  justify-content: center;
  color: #fff;
  align-items: center;
  margin: 0 auto 0 510px;
}

.video-area {
  grid-area: video-area;
  position: relative;
}

.canvas-wrapper {
  grid-area: video;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: cover;

  border: 1px solid #000;
  font-family: "Arial", "Sans-serif";

  img,
  svg,
  video {
    position: relative;
    top: 0;
    left: 0;
    margin: auto;
    border: 1px solid #000;
    overflow: hidden;
  }

  g {
    fill: none;
    stroke: #000;
    stroke-width: 3;

    &.shapes {
      stroke: rgb(12, 23, 175);
    }
  }

  .control {
    fill: rgba(255, 255, 255, 1);
    stroke: #000;
    stroke-width: 1;
  }

  .polygon-drawing {
    stroke: #f33;
  }

  .annotation-tag {
    font-family: "monospace";
    // font-stretch: ultra-condensed;
    cursor: pointer;
    font-size: 14px;
    stroke: none;
    fill: white;
    stroke-width: 1;
    font-weight: normal;
  }
}

.right-pane {
  grid-area: right-pane;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 280px);
}

.option-view {
  height: auto;
  overflow-y: visible;
}

.property-view {
  height: auto;
  overflow-y: visible;
}

.label-list {
  visibility: hidden;
  height: auto;
  overflow-y: scroll;
}

.btn {
  img {
    width: 24px;
    height: 24px;
  }

  &.active {
    background-color: red;
  }
}

.btn2 {
  width: 32px;
  height: 32px;
  border: none;
  background: transparent;
  color: #eee;
  cursor: pointer;
}

.seeker-bar {
  grid-area: video;
  position: relative;
  z-index: 200;
  width: calc(100% - 5px);
  left: 5px;
  height: 130px;
  transform: translateY(-110px);
}

.time-indicator {
  grid-area: video;
  position: relative;
  z-index: 199;
  margin-left: calc(100% - 10rem);
  padding: 2px 5px;
  height: 20px;
  border-radius: 5px;
  background-color: #333;
  color: white;
  font-size: 16px;
  text-align: center;
  transform: translateY(-150px);
}

.debug {
  color: white;
}
