
.main-wrap {
  max-width: 1600px;
  padding: 80px 20px;
  margin: 0 auto;

  h2 {
    color: #0F0E4C;
    font-size: 40px;
    font-weight: 800;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  span {
    display: block;
    margin-top: 15px;
    margin-bottom: 50px;
    color: #333;
    font-size: 20px;
    font-weight: 400;
  }
}

.card-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;


  .card {
    position: relative;
    border: 1px solid #ccc;
    overflow: hidden;
    transition: box-shadow 0.3s;
    cursor: pointer;

    .photo {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      top: 0;
      left: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .card-content {
      * {
        color: black;
        text-decoration: none;
      }

      position: absolute;
      bottom: 20px;
      left: 10px;
      padding: 1rem;

      h3 {
        color: #FFF;
        font-size: 30px;
        font-weight: 900;
        margin: 0;
      }

      p {
        color: #FFF;
        font-size: 18px;
        font-weight: 600;
      }

      span {
        color: rgb(252, 240, 74);
        font-size: 18px;
        font-weight: 200;
        line-height: 25px;
      }
    }

  }
}

@media(max-width: 1020px) {
  .main-wrap {
    padding: 80px 20px;

    .card-list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media(max-width: 700px) {
  .main-wrap {
    padding: 80px 20px;

    .card-list {
      grid-template-columns: repeat(1, 1fr);

      .card-content {
        * {
          color: black;
          text-decoration: none;
        }

        position: absolute;
        bottom: -10px;
        left: -10px;
        padding: 1rem;

        h3 {
          color: #FFF;
          font-size: 15px;
          font-weight: 700;
          margin: 0;
        }

        p {
          color: #FFF;
          font-size: 14px;
          font-weight: 400;
        }

        span {
          color: rgb(252, 240, 74);
          font-size: 14px;
          font-weight: 200;
          line-height: 20px;
        }
      }
    }
  }
}
