
.main-container {
  display: flex;
}

button {
  border-radius: 50px;
  background-color: white;
  border: 1px solid #0F0E4C;
  color: #0F0E4C;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 30px 0;
  font-weight: 700;
  cursor: pointer;
}

.col {
  width: 50%;
  padding: 0 100px;
  margin-top: 120px;

  .signup-go {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    padding: 0 20px;
    margin-top: 20px;
    text-align: end;
    color: #bbb;

    a {
      color: #4d4d4d;
    }
  }

  h2 {
    color: #0F0E4C;
    font-size: 40px;
    font-weight: 700;
  }

  p {
    width: 100%;
    padding-top: 10px;

  }

  label {
    display: block;
    color: #222;
    font-size: 18px;
    font-weight: 500;

    b {
      color: #F00;
      font-size: 15px;
      font-weight: 500;
    }

    @media (min-width: 1024px) {}

    &.privacy-policy-agreement {
      padding: 0 5px;
      text-overflow: ellipsis;
      font-size: 1em !important;
    }
  }

  input,
  select {
    margin-top: 5px;
    padding: 10px 5px;
    display: block;
    width: 100%;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #bbb;

    &::placeholder {
      @extend %my-placeholder;
    }
  }
}

%my-placeholder {
  color: #9E9E9E;
  font-size: 14px;
  font-weight: 400;
}

.singin-img {
  width: 50%;

  img {
    width: 100%;
    height: 100%;
  }
}

.signin-area {

  .signin-form {
    background: #FFF;
    padding: 60px;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.10);
  }

  .sign-in-button {
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      width: 100%;
    }
  }
}

footer {
  width: 100%;
  height: 5%;
  text-align: center;
  font-family: sans-serif;
  font-size: 16px;
  color: #333;
}

@media (max-width: 1024px) {
  .singin-img {
    display: none;
  }

  .col {
    width: 100%;
    padding: 0 20px;
    margin: 60px 0;
  }
}
